<template>
  <div class="navbar">
    <nav class="mx-auto flex max-w-7xl items-center justify-between lg:px-8 px-5" style="height: 60px"
      aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="/" class="flex flex-row items-center justify-center -m-1.5 p-1.5">
          <img class="h-12 w-auto" src="../assets/meshnet-logo-white.svg" alt="" />
          <h1 class="text-gray-200 text-xl ml-3">MeshNet.si</h1>
        </a>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.navbar {
  background-color: #242526;
}
</style>
