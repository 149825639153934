import type { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
  path: "/how-to",
  name: "how-to",
  component: () => import("./How-To-Index.vue"),
  meta: {
    title: "How-To",
  },
};

export default route;
