<template>
  <footer class="flex items-center justify-center">
    <p>
      Poganja
      <a href="https://gitplac.si" class="green-underline" target="_blank">Gitplac.si</a>
      |
      <a href="https://gitplac.si/meshnet.si/meshnet.si" class="green-underline" target="_blank"><i
          class="fa fa-gitlab fa-lg"></i> Izvorna koda</a>
      | © <router-link to="/">Meshnet.si</router-link> {{ new Date().getFullYear() }}
    </p>
  </footer>
</template>

<style scoped>
footer {
  padding: 30px 20px;
  color: #e5e7eb;
  background-color: #1c1e21;
}
</style>
