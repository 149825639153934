import type { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
  path: "/how-to/nastavi-svoj-node",
  name: "nastavi-svoj-node",
  component: () => import("./Nastavi-Svoj-Node-Index.vue"),
  meta: {
    title: "Nastavi svoj node",
  },
};

export default route;
