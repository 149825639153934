import { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
  path: "/",
  name: "domov",
  component: () => import("./Domov-Index.vue"),
  meta: {
    title: "Domov",
  },
};

export default route;
