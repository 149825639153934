import {
	createRouter,
	createWebHashHistory,
	type Router,
	type RouteRecordRaw,
} from "vue-router";
import Domov from "../routes/Domov/router";
import Kanali from "../routes/Kanali/router";
import Orodja from "../routes/Orodja/router";
import KodiranjeDekodiranjeKanalov from "@/routes/Orodja/Kodiranje-Dekodiranje-Kanalov/router";
import HowTo from "../routes/How-To/router";
import NastaviSvojNodeIndex from "@/routes/How-To/Nastavi-Svoj-Node/router";
import KakoUvazatiKanale from "../routes/How-To/Hiter-Zacetek/router";
import FakeTecNavodila from "../routes/How-To/FakeTec-Navodila/router";
import SolarniNodeNavodila from "../routes/How-To/Solarni-Node-Navodila/router";
import Stran404 from "../routes/Stran404/router";

const routes: Array<RouteRecordRaw> = [
	Domov,
	Kanali,
	Orodja,
	KodiranjeDekodiranjeKanalov,
	HowTo,
	NastaviSvojNodeIndex,
	FakeTecNavodila,
	SolarniNodeNavodila,
	KakoUvazatiKanale,
	Stran404,
];

const router: Router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes: [{ path: "/:pathMatch(.*)*", redirect: "/404" }, ...routes],
});

router.afterEach(async (to) => {
	switch (typeof to.meta?.title) {
		case "function": {
			window.document.title = `${await to.meta.title} :: MeshNet.si`;
			break;
		}
		case "string": {
			if (to.meta.title === "Domov") window.document.title = "MeshNet.si";
			else window.document.title = `${to.meta.title} :: MeshNet.si`;
			break;
		}
		default: {
			const name = to.name?.toString();
			window.document.title = `${name?.charAt(0).toUpperCase()}${
				name?.slice(
					1,
				)
			} :: MeshNet.si`;
			break;
		}
	}
});

export default router;
