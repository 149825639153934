import { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
  path: "/orodja/kodiranje-dekodiranje-kanalov",
  name: "Kodiranje Dekodiranje Kanalov",
  component: () => import("./Kodiranje-Dekodiranje-Kanalov-Index.vue"),
  meta: {
    title: "Kodiranj Dekodiranje Kanalov",
  },
};

export default route;
