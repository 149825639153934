import { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
  path: "/orodja",
  name: "orodja",
  component: () => import("./Orodja-Index.vue"),
  meta: {
    title: "Orodja",
  },
};

export default route;
