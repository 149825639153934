import type { RouteRecordRaw } from "vue-router";

const route: RouteRecordRaw = {
	path: "/how-to/solarni-node-navodila",
	name: "solarni-node-navodila",
	component: () => import("./Solarni-Node-Navodila-Index.vue"),
	meta: {
		title: "Solarni Node Navodila",
	},
};

export default route;
